<template>
  <v-container class="d-flex justify-center align-center">
    <div class="error-wrap">
      <v-icon class="icon-warning orange--text text--darken-2 1">mdi-exclamation-thick</v-icon>
      <h1 v-if="error.statusCode === 404">
        {{ pageNotFound }}
      </h1>
      <h1 v-else>
        {{ otherError }}
      </h1>

      <v-btn v-if="isAdminPage" outlined @click="backToPrev()"> 이전 페이지로 돌아가기 </v-btn>
      <NuxtLink v-else to="/"> 재료공장 홈페이지로 돌아가기 </NuxtLink>
    </div>
  </v-container>
</template>

<script>
export default {
  layout(context) {
    return context.route.path.startsWith('/admin') ? 'admin/default' : 'default';
  },

  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '페이지를 찾을 수 없습니다.',
      otherError: '에러가 발생했습니다.',
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
  computed: {
    statusCode () {
      return (this.error && this.error.statusCode) || 500
    },
    message () {
      return this.error.message || 'Error'
    },
    isAdminPage() {
      return this.$route.path.startsWith('/admin')

    },
  },
  methods: {
    backToPrev() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.error-wrap {
  text-align: center;
  margin: 40px auto;
  max-width: 460px;
  width: 460px;

  .v-icon {
    display: block;
    font-size: 7rem;
    margin-bottom: 1rem;
  }
  h1 {
    display: block;
  }
  a {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    background: $color__sig;
    color: #fff;
    border-radius: 4px;
    margin-top: 30px;
  }
}
</style>
