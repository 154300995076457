// Nuxt에서는 아래와 같이 하나하나하나하나하나하나 export 시켜줘야한다. 안그러면
//  WARN  'state' should be a method that returns an object in store/drawer.js 이따위 문구를 볼것이다.

export const state = () => ({
  eoh: false,
  mini: false,
  darkmode: false,
})

export const mutations = {
  CHANGE_DRAWER_STATUS(state) {
    state.eoh = !state.eoh
    state.mini = !state.mini
  },

  TOGGLE_DARK_MODE(state) {
    //
    // console.log(this.app.context.route.path)
    state.darkmode = !state.darkmode
    window.$nuxt.$root.$vuetify.theme.dark = state.darkmode
  },

  SET_MOBILE(state) {
    state.eoh = false
    state.mini = false
  },
}

export const actions = {
  toggleMini({ commit }) {
    commit('CHANGE_DRAWER_STATUS')
  },

  toggleDarkMode({ commit }) {
    //
    // console.log(this.app.context.route.path)
    commit('TOGGLE_DARK_MODE')
  },
  setMobile({ commit }) {
    commit('SET_MOBILE')
  },
}

export const getters = {}
