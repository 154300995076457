<template>
  <v-app>
    <MinimalGnb />
    <v-main>
      <Nuxt />
    </v-main>
    <div class="containment-wrapper">
      <Confirm />
      <Snackbar />
    </div>
  </v-app>
</template>

<script>
import Snackbar from '~/components/_common/Snackbar.vue'
import Confirm from '~/components/_common/Confirm.vue'
import MinimalGnb from '~/components/frontend/MinimalGnb.vue'

export default {
  components: {
    Snackbar,
    Confirm,
    MinimalGnb,
  },
}
</script>
