export default async ({ app, store, route, redirect, error }) => {
  const pathSegments = route.path.split('/')
  const target = pathSegments[1].startsWith('admin') ? pathSegments[2] : pathSegments[1]
  const id = pathSegments[1].startsWith('admin') ? pathSegments[3] : pathSegments[2]

  const response = await app.$axios.get('/checkOwner', {
    headers: {
      Authorization: `Bearer ${store.state.auth.token}` // 인증 토큰 전송
    },
    params: {
      target,
      id
    }
  })

  if (!response.data.result) {
    // return redirect(`/${target}/${id}`)
    return error({ statusCode: 404, message: '페이지를 찾을 수 없습니다.' })
  }
}
