export default ({ app, route, store, redirect }) => {
  if (/^\/admin/i.test(route.path)) {
    if (route.matched.length > 0 && route.matched[0].components.default) {
      if (app.$auth.loggedIn) {
        route.matched[0].components.default.options.layout = 'admin/default'
      } else {
        route.matched[0].components.default.options.layout = 'admin/public'
      }
    }
  }
}
