import Vue from 'vue'
import ko from 'vee-validate/dist/locale/ko.json'
import { extend, ValidationObserver, ValidationProvider, localize } from 'vee-validate/dist/vee-validate.full.esm'

/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as rules from 'vee-validate/dist/rules'


extend('alphanum', {
  validate(value) {
    return /^[a-zA-Z0-9]+$/.test(value);
  },
  message: '아이디는 영문 대소문자와 숫자만 포함할 수 있습니다.'
});

extend('verify_password', {
  validate: value => {
    // const strongRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@#$%^&+=*.\-_]){8,}$/
    // const strongRegex = /^(?=.*[0-9])(?=.*[a-z])([a-zA-Z0-9@#$%^&+=*.\-_]){8,}$/
    // const strongRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[@#$%^&+=*.\-_]).{8,}$/
    const strongRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[@#$%^&+=*.\-_]).{8,}$/

    return strongRegex.test(value)
  },
  message: '{_field_}는 영문,숫자,특수문자를 포함하여 8자이상 입력해주세요.'
})

extend('noSpaces', {
  validate: value => !/\s/.test(value),
  message: '공백은 허용되지 않습니다'
})

extend('is_same_as_password', {
  validate: (value, { password }) => value === password,
  message: '비밀번호가 동일하지 않습니다.',
  params: ['password']
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

localize({
  ko: {
    messages: ko.messages,
    names: ko.names,
    fields: ko.fields
  }
})

localize('ko')
// import Vue from 'vue'

// import {
//   extend,
//   ValidationObserver,
//   ValidationProvider
// } from 'vee-validate/dist/vee-validate.full'

// /* 개별로 등록할 때
// import { required, email, min } from 'vee-validate/dist/rules'

// extend('email', {
//   ...email,
//   message: '올바르지 않은 이메일 주소입니다.',
// })

// extend('required', {
//   ...required,
//   message: '{_field_}(은)는 필수 입력사항입니다.',
// })

// extend('min', {
//   ...min,
//   message: '{_field_}는 {length}자 이상 입력하셔야 합니다.',
// })
// */

// import * as rules from 'vee-validate/dist/rules'

// extend('has_files', {
//   validate: value => value,
//   message: '이미지는 최소 1개 이상이어야 합니다.'
// })

// extend('email', {
//   ...rules.email,
//   message: '올바르지 않은 이메일 주소입니다.'
// })

// extend('required', {
//   ...rules.required,
//   message: '{_field_}(은)는 필수 입력사항입니다.'
// })
// /*
// extend('min', {
//   ...rules.min,
//   message: '{_field_}(은)는 {length}자 이상 입력하셔야 합니다.'
// })

// extend('max', {
//   ...rules.max,
//   message: '{_field_}(은)는 {length}자 이하 입력하셔야 합니다.'
// })
//  */
// extend('min', {
//   validate: (value, { length }) => String(value).trim().length >= length,
//   params: ['length'],
//   message: '{_field_}(은)는 {length}자 이상 입력하셔야 합니다.'
// })

// extend('max', {
//   validate: (value, { length }) => String(value).trim().length <= length,
//   params: ['length'],
//   message: '{_field_}(은)는 {length}자 이하 입력하셔야 합니다.'
// })

// extend('verify_password', {
//   validate: value => {
//     const strongRegex =
//       /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@#$%^&+=*.\-_]){8,}$/
//     return strongRegex.test(value)
//   },
//   message: '{_field_}는 영문,숫자,특수문자를 포함하여 8자이상 입력해주세요.'
// })

// extend('is_same_as_password', {
//   validate: (value, { password }) => value === password,
//   message: '비밀번호가 동일하지 않습니다.',
//   params: ['password']
// })

// const components = { ValidationProvider, ValidationObserver }

// Object.entries(components).forEach(([name, component]) => {
//   Vue.component(name, component)
// })
