// ~/store/snackbar.js

export const state = () => ({
  content: '',
  color: '',
  delay: 3000,
})

export const mutations = {
  showMessage(state, payload) {
    state.content = payload.content
    state.color = payload.color
    state.delay = payload.delay
  },
}
