<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="persistent" :max-width="width">
      <v-card>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="yesFunc"
            ref="confirmBtn"
            dark
            small
            elevation="0"
            :color="yesColor"
            @click.prevent="confirm"
          >
            {{ yesTxt }}
          </v-btn>
          <v-btn
            v-show="noTxt"
            ref="cancelBtn"
            small
            :color="noColor"
            text
            @click="cancel"
          >
            {{ noTxt }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      show: '',
      title: '',
      message: '',
      yesTxt: '',
      noTxt: '',
      yesColor: '',
      noColor: '',
      yesFunc: '',
      persistent: '',
      width: '',
    }
  },
  watch: {
    show(val) {
      if (!val) return
      this.$nextTick(() => {
        if (this.$refs.confirmBtn && this.$refs.confirmBtn.$el) {
          this.$refs.confirmBtn.$el.focus()
        } else {
          this.$refs.cancelBtn.$el.focus()
        }
      })
    },
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'confirm/showConfirm') {
        this.show = state.confirm.show
        this.title = state.confirm.title
        this.message = state.confirm.message
        this.yesTxt = state.confirm.yesTxt
        this.noTxt = state.confirm.noTxt
        this.yesColor = state.confirm.yesColor
        this.noColor = state.confirm.noColor
        this.yesFunc = state.confirm.yesFunc
        this.persistent = state.confirm.persistent
        this.width = state.confirm.width
      }

      if (mutation.type === 'confirm/closeDialog') {
        this.show = state.confirm.show
      }
    })
  },
  methods: {
    confirm() {
      this.$store.state.confirm.yesFunc()
      // this.$nuxt.$emit(this.yesFunc)
      this.$store.dispatch('confirm/closeDialog')
    },
    cancel() {
      if (this.$store.state.confirm.noFunc) {
        this.$store.state.confirm.noFunc()
      }
      this.$store.dispatch('confirm/closeDialog')
    },
  },
}
</script>
