export const state = () => ({
  module: null,
  moduleId: null,
  comments: [],
  comment: null,
  totalCount: 0,
  editedComments: {},
  replyText: {},
  replyingCommentId: null,
  highlightedComments: {}
})

const removeComment = function (comments, id) {
  return comments.flatMap(comment => {
    if (comment.id === id) {
      return []
    }
    if (comment.children) {
      return {
        ...comment,
        children: removeComment(comment.children, id)
      }
    }
    return comment
  })
}

const findAndReplaceComment = function (comments, updatedComment) {
  for (let i = 0; i < comments.length; i++) {
    if (comments[i].id === updatedComment.id) {
      comments[i] = updatedComment
      return true
    } else if (comments[i].children && comments[i].children.length > 0) {
      if (findAndReplaceComment(comments[i].children, updatedComment)) {
        return true
      }
    }
  }
  return false
}

export const mutations = {
  ADD_HIGHLIGHT (state, commentId) {
    state.highlightedComments[commentId] = true
  },
  REMOVE_HIGHLIGHT (state, commentId) {
    delete state.highlightedComments[commentId]
  },
  SET_COMMENT (state, comment) {
    state.comment = comment
  },

  SET_MODULE (state, module) {
    state.module = module
  },

  SET_MODULE_ID (state, moduleId) {
    state.moduleId = moduleId
  },

  ADD_COMMENT (state, comment) {
    const { parent_id: parentId } = comment

    const findCommentAndAddChild = comments => {
      for (const c of comments) {
        if (c.id === parentId) {
          if (!c.children) {
            c.children = []
          }
          c.children.unshift({ ...comment, children: [] })
          return true
        } else if (c.children) {
          if (findCommentAndAddChild(c.children)) {
            return true
          }
        }
      }
      return false
    }

    if (parentId === null) {
      state.comments.unshift({ ...comment, children: [] })
    } else {
      const result = findCommentAndAddChild(state.comments)
      if (!result) {
        console.warn(`Failed to add comment with parent id ${parentId}`)
      }
    }
    state.comment = null
  },

  SET_COMMENTS (state, comments) {
    state.comments = comments
  },
  SET_TOTAL_COUNT (state, totalCount) {
    state.totalCount = totalCount
  },
  SET_EDITED_COMMENT (state, { id, body }) {
    state.editedComments[id] = { body }
  },
  REMOVE_EDITED_COMMENT (state, id) {
    delete state.editedComments[id]
  },
  UPDATE_COMMENT (state, { id, body }) {
    const commentIndex = state.comments.findIndex(comment => comment.id === id)
    if (commentIndex !== -1) {
      state.comments[commentIndex].body = body
    }
  }
}

export const actions = {
  setComment ({ commit }, comment) {
    commit('SET_COMMENT', comment)
  },
  addComment ({ commit }, comment) {
    commit('ADD_COMMENT', comment)
  },
  setModule ({ commit }, module) {
    commit('SET_MODULE', module)
  },

  setModuleId ({ commit }, moduleId) {
    commit('SET_MODULE_ID', moduleId)
  },

  increaseTotalCount ({ commit, state }, addedComment) {
    commit('SET_TOTAL_COUNT', state.totalCount + addedComment)
  },

  decreaseTotalCount ({ commit, state }, deletedComment) {
    commit('SET_TOTAL_COUNT', state.totalCount - deletedComment)
  },

  async saveComment ({ commit, dispatch, state }) {
    try {
      const response = await this.$axios.post('/comment/store', {
        moduleId: state.moduleId,
        module: state.module,
        comment: state.comment
      })
      // 처리 로직 추가
      const comment = {
        avatar: response.data.comment.user.avatar,
        body: response.data.comment.body,
        created_at: response.data.comment.created_at,
        depth: response.data.comment.depth,
        id: response.data.comment.id,
        nickname: response.data.comment.user.nickname,
        user_id: response.data.comment.user.id,
        parent_id: response.data.comment.parent_id
      }

      commit('ADD_COMMENT', comment)

      setTimeout(() => {
        const element = document.getElementById(`comment-${comment.id}`)
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          })
        }
      }, 0)

      commit('ADD_HIGHLIGHT', comment.id)
      dispatch('increaseTotalCount', 1)
      setTimeout(() => {
        commit('REMOVE_HIGHLIGHT', comment.id)
      }, 2000)
    } catch (error) {
      console.error(error)
    }
  },

  async fetchComments ({ commit, state }) {
    try {
      const response = await this.$axios.get(`/comment/getComments`, {
        params: {
          module: state.module,
          moduleId: state.moduleId
        }
      })

      commit('SET_TOTAL_COUNT', response.data.totalCount || 0)
      commit('SET_COMMENTS', response.data.comments || [])
    } catch (error) {
      console.log(error)
    }
  },

  async deleteComment ({ commit, dispatch, state }, { module, id }) {
    await this.$confirm.showConfirm({
      show: true,
      title: '댓글을 삭제하시겠습니까?',
      message: '삭제된 댓글은 복구할 수 없습니다.',
      yesTxt: '삭제',
      noTxt: '취소',
      yesFunc: async () => {
        try {
          const response = await this.$axios.delete(`/comment`, {
            params: {
              id
            }
          })

          if (response.data.comment) {
            // 코멘트가 리턴된 경우 해당 코멘트를 변경한다.
            const comment = {
              avatar: response.data.comment.user.avatar,
              body: response.data.comment.body,
              created_at: response.data.comment.created_at,
              depth: response.data.comment.depth,
              id: response.data.comment.id,
              nickname: response.data.comment.user.nickname,
              user_id: response.data.comment.user.id,
              parent_id: response.data.comment.parent_id
            }
            console.log(response.data.comment)

            if (!findAndReplaceComment(state.comments, comment)) {
              console.warn(`Failed to update comment with id ${comment.id}`)
            }
          } else {
            // id만 리턴된 경우 해당 코멘트를 삭제한다.
            const updatedComments = removeComment(state.comments, id)
            commit('SET_COMMENTS', updatedComments)
            dispatch('decreaseTotalCount', 1)
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  // async submitEdit ({ commit, state }, id) {
  //   const { body } = state.editedComments[id]

  //   try {
  //     const { data } = await this.$axios.patch(`/comment/update`, {
  //       id,
  //       body
  //     })
  //     console.log(data)

  //     if (data.result) {
  //       commit('UPDATE_COMMENT', { id, body: data.comment })
  //       commit('REMOVE_EDITED_COMMENT', id)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
}
