import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Code from '@editorjs/code'
import Delimiter from '@editorjs/delimiter'
import Embed from '@editorjs/embed'
import Image from '@editorjs/image'
import InlineCode from '@editorjs/inline-code'
import LinkTool from '@editorjs/link'
import List from '@editorjs/list'
import Paragraph from '@editorjs/paragraph'
import Quote from '@editorjs/quote'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline'
import Attaches from '@editorjs/attaches'

export default ({ app }, inject) => {
  const defaultOptions = {
    id: '',
    data: {},
    onChange: () => {}
  }

  const editor = (options = defaultOptions) => {
    return new EditorJS({
      placeholder: '내용을 입력하세요.',
      holder: options.id,
      autofocus: false,

      tools: {
        header: {
          class: Header,
          config: {
            placeholder: '소제목',
            levels: [2, 3, 4],
            defaultLevel: 2
          }
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            placeholder: '내용을 입력하세요'
          }
        },
        list: {
          class: List,
          inlineToolbar: true
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: '인용구 입력',
            captionPlaceholder: '인용구의 저자'
          }
        },
        table: {
          class: Table,
          inlineToolbar: true
        },
        code: {
          class: Code,
          shortcut: 'CMD+SHIFT+C'
        },
        image: {
          class: Image,
          config: {
            captionPlaceholder: '이미지 설명',
            // endpoints: {
            //   byFile: '/api/v1/editor/uploadImage',
            // },
            // additionalRequestHeaders: {
            //   'X-XSRF-TOKEN': app.$cookies.get('XSRF-TOKEN')
            // }
            uploader: {
              uploadByFile (file) {
                const formData = new FormData()
                formData.append('image', file)
                return app.$axios
                  .$post('/editor/uploadImage', formData, {
                    headers: {
                      'X-XSRF-TOKEN': app.$cookies.get('XSRF-TOKEN')
                    }
                  })
                  .then(response => {
                    if (response.success) {
                      return {
                        success: 1,
                        file: {
                          url: response.file.url,
                          name: response.file.name,
                          size: response.file.size,
                          extension: response.file.extension
                        }
                      }
                    } else {
                      console.log('upload failed')
                    }
                  })
                  .catch(error => {
                    throw error
                  })
              }
            }
          }
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true,
              twitter: true,
              instagram: true
            }
          }
        },
        delimiter: Delimiter,
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M'
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: '/api/v1/editor/fetchUrl'
          }
        },
        attaches: {
          class: Attaches,
          config: {
            uploader: {
              uploadByFile (file) {
                const formData = new FormData()
                formData.append('file', file)
                return app.$axios
                  .$post('/editor/uploadFile', formData, {
                    headers: {
                      'X-XSRF-TOKEN': app.$cookies.get('XSRF-TOKEN')
                    }
                  })
                  .then(response => {
                    if (response.success) {
                      return {
                        success: 1,
                        file: {
                          url: response.file.url,
                          name: response.file.name,
                          size: response.file.size,
                          extension: response.file.extension,
                          title: response.file.title
                        }
                      }
                    } else {
                      throw response.error
                    }
                  })
                  .catch(error => {
                    throw error
                  })
              }
            },
            types: '.zip',
            buttonText: '파일 선택',
            errorMessage: '파일 업로드에 실패했습니다.'
          }
        },
        underline: Underline
      },
      i18n: {
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': '설정하려면 클릭',
                'or drag to move': '또는 드래그하여 이동',
                'Click to delete': '삭제하려면 클릭'
              }
            },
            inlineToolbar: {
              converter: {
                'Convert to': '변환하기'
              }
            },
            toolbar: {
              toolbox: {
                Add: '추가'
              }
            }
          },
          toolNames: {
            Text: '문단',
            Heading: '제목',
            List: '목록',
            Warning: '주의',
            Checklist: '체크리스트',
            Quote: '인용구',
            Code: '코드',
            Delimiter: '구분자',
            'Raw HTML': 'HTML 코드',
            Table: '테이블',
            Link: '링크',
            Marker: '마커',
            Bold: '굵게',
            Image: '이미지',
            Italic: '기울임꼴',
            InlineCode: '인라인 코드',
            Attachment: '파일첨부'
          },
          tools: {
            attaches: {
              File: '파일',
              'Select file': '파일 선택',
              'Replace file': '파일 교체',
              'Can not paste such link': '링크를 붙여넣을 수 없습니다.'
            },
            warning: {
              Title: '제목',
              Message: '메시지'
            },
            link: {
              'Add a link': '링크 추가'
            },
            stub: {
              'The block can not be displayed correctly.': '블록이 올바르게 표시되지 않습니다.'
            }
          },

          blockTunes: {
            delete: {
              Delete: '삭제'
            },
            moveUp: {
              'Move up': '위로 이동'
            },
            moveDown: {
              'Move down': '아래로 이동'
            }
          }
        }
      },

      onChange (data) {
        options.onChange(data)
      },
      data: options.data || {}
    })
  }

  inject('editor', options => editor(options))
}
