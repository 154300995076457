<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    {{ message }}
    <!-- <v-btn text @click="show = false">Close</v-btn> -->
    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      multiLine: true,
      message: '',
      color: '',
      timeout: 3000,
    }
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        this.message = state.snackbar.content
        this.color = state.snackbar.color
        this.timeout = state.snackbar.delay
        this.show = true
      }
    })
  },
}
</script>
