export const state = () => ({
  allowedMimes: [
    {
      mime: 'application/pdf',
      icon: 'file-pdf-box',
      title: 'PDF',
    },
    {
      mime: 'application/zip',
      icon: 'zip-box',
      title: 'ZIP',
    },
    {
      mime: 'video/mp4',
      icon: 'video-box',
      title: 'MP4',
    },
    {
      mime: 'video/mpeg',
      icon: 'video-box',
      title: 'MPEG',
    },
    {
      mime: 'video/webm',
      icon: 'video-box',
      title: 'WEBM',
    },
    {
      mime: 'image/png',
      icon: 'file-png-box',
      title: 'PNG',
    },
    {
      mime: 'image/gif',
      icon: 'file-gif-box',
      title: 'GIF',
    },
    {
      mime: 'image/jpeg',
      icon: 'file-jpg-box',
      title: 'JPG',
    },
    {
      mime: 'image/webp',
      icon: 'image',
      title: 'WEBP',
    },
  ],
  uploadSize: 1,
})
