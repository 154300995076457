<template>
  <v-app>
    <client-only>
      <SiteHeader />
    </client-only>
    <v-main>
      <div class="content">
        <Nuxt />
      </div>
    </v-main>
    <client-only>
      <SiteFooter />
    </client-only>
    <div class="containment-wrapper">
      <Confirm />
      <Snackbar />
    </div>
  </v-app>
</template>

<script>
import SiteHeader from '~/components/frontend/SiteHeader.vue'
import SiteFooter from '~/components/frontend/SiteFooter.vue'
import Snackbar from '~/components/_common/Snackbar.vue'
import Confirm from '~/components/_common/Confirm.vue'

export default {
  components: {
    SiteHeader,
    SiteFooter,
    Snackbar,
    Confirm,
  },
  computed: {
    isRootPath() {
      return this.$store.state.checkRootPath.isRootPath
    },
  },
}
</script>
