export const state = () => ({
  itemsPerPage: 20,
})

export const mutations = {
  CHANGE_IPP(state, payload) {
    state.itemsPerPage = payload.count
  },
}

export const actions = {
  change_items_per_page({ commit }, payload) {
    commit('CHANGE_IPP', payload)
  },
}

export const getters = {}
