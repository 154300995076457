export const state = () => ({
  currentPage: 1,
  countPerPage: 10,
  resultType: 'json',
  keyword: '',

  addresses: [],
  totalPages: 1,
  totalCount: 0,
})

export const mutations = {
  setKeyword(state, keyword) {
    state.keyword = keyword
  },
  setTotalCount(state, totalCount) {
    state.totalCount = totalCount
  },
  setAddresses(state, addresses) {
    state.addresses = addresses
  },
  setTotalPages(state, totalPages) {
    state.totalPages = totalPages
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },
  resetAddressData(state) {
    state.keyword = ''
    state.addresses = []
    state.currentPage = 1
    state.totalPages = 1
    state.totalCount = 0
    state.keyword = ''
  },
}

export const actions = {
  async searchAddress({ commit, state }) {
    const requestData = {
      currentPage: state.currentPage,
      countPerPage: state.countPerPage,
      resultType: state.resultType,
      keyword: state.keyword,
    }
    const response = await this.$axios.post('/searchAddr', requestData)
    const addrData = response.data
    commit('setTotalCount', addrData.results.common.totalCount)
    if (addrData.results.common.totalCount <= 0) {
      this.$snackbar.showMessage({
        content: '검색 결과가 없습니다.',
        color: 'orange darken-4',
        delay: 3000,
      })
      return false
    }
    commit('setAddresses', addrData.results.juso)
    commit(
      'setTotalPages',
      Math.ceil(addrData.results.common.totalCount / state.countPerPage)
    )
  },
}
