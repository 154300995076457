// ~/store/confirm.js

export const state = () => ({
  show: '',
  title: '',
  message: '',
  yesTxt: '',
  noTxt: '',
  yesColor: '',
  noColor: '',
  yesFunc: '',
  noFunc: '',
  persistent: '',
  width: '',
})

export const mutations = {
  showConfirm(state, payload) {
    state.show = payload.show
    state.title = payload.title
    state.message = payload.message
    state.yesTxt = payload.yesTxt
    state.noTxt = payload.noTxt
    state.yesColor = payload.yesColor
    state.noColor = payload.noColor
    state.yesFunc = payload.yesFunc
    state.noFunc = payload.noFunc
    state.persistent = payload.persistent
    state.width = payload.width
  },
  closeDialog(state) {
    state.show = false
  },
}

export const actions = {
  closeDialog({ commit, state }) {
    commit('closeDialog')
  },
}
