<template>
  <header>
    <v-container class="header-wrap">
      <div>
        <NuxtLink id="header-logo" to="/">
          <!-- <v-img id="header-logo" max-width="193" src="/images/header-logo@2x.png" alt="재료공장 로고" transition="none"></v-img> -->
          <img src="/images/header-logo@2x.png" alt="재료공장 로고" />
        </NuxtLink>
      </div>
      <div class="site-nav">
        <button v-if="isMobile" class="hamburger" @click="toggleMenu">
          <v-icon>mdi-menu</v-icon>
        </button>
        <collapse-transition v-if="isMobile">
          <ul v-show="showMenu" id="gnb">
            <li>
              <NuxtLink exact-active-class="on" to="/map"> 지도에서 재료찾기 </NuxtLink>
            </li>
            <li>
              <NuxtLink
                exact-active-class="on"
                :to="{
                  path: '/material',
                  query: {},
                }"
              >
                재료 목록
              </NuxtLink>
            </li>
            <li>
              <NuxtLink exact-active-class="on" to="/upcycleGallery"> 업사이클 갤러리 </NuxtLink>
            </li>
            <li>
              <NuxtLink exact-active-class="on" to="/notice">공지사항</NuxtLink>
            </li>
            <li>
              <NuxtLink exact-active-class="on" to="/community">커뮤니티</NuxtLink>
            </li>
            <li v-if="!$auth.loggedIn">
              <NuxtLink exact-active-class="on" to="/login" class="login"> 로그인 </NuxtLink>
            </li>
            <li v-if="!$auth.loggedIn">
              <NuxtLink exact-active-class="on" to="/register" class="register">회원가입</NuxtLink>
            </li>
          </ul>
        </collapse-transition>
        <ul v-else id="gnb">
          <li>
            <NuxtLink exact-active-class="on" to="/map"> 지도에서 재료찾기 </NuxtLink>
          </li>
          <li>
            <NuxtLink
              exact-active-class="on"
              :to="{
                path: '/material/',
                query: {},
              }"
            >
              재료 목록
            </NuxtLink>
          </li>
          <li>
            <NuxtLink exact-active-class="on" to="/upcycleGallery"> 업사이클 갤러리 </NuxtLink>
          </li>
          <li>
              <NuxtLink exact-active-class="on" to="/notice">공지사항</NuxtLink>
            </li>
          <li>
            <NuxtLink exact-active-class="on" to="/community">커뮤니티</NuxtLink>
          </li>
          <li v-if="!$auth.loggedIn">
            <NuxtLink exact-active-class="on" to="/login" class="login"> 로그인 </NuxtLink>
          </li>
          <li v-if="!$auth.loggedIn">
            <NuxtLink exact-active-class="on" to="/register" class="register">회원가입</NuxtLink>
          </li>
        </ul>
        <div v-if="$auth.loggedIn">
          <v-menu bottom min-width="200px" rounded offset-y>
            <template #activator="{ on }">
              <v-btn icon small v-on="on">
                <v-avatar color="primary" :size="isMobile ? 24 : 36">
                  <img v-if="$auth.user.avatar !== '' && $auth.user.avatar" alt="Avatar" :src="$auth.user.avatar" />
                  <span v-else>
                    <v-icon class="white--text text-h6">mdi-account</v-icon>
                  </span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card elevation="0">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <div class="mx-auto text-center">
                      <v-avatar color="primary" size="48">
                        <img v-if="$auth.user.avatar !== '' && $auth.user.avatar" alt="Avatar" :src="$auth.user.avatar" />
                        <span v-else>
                          <v-icon class="white--text text-h3">mdi-account</v-icon>
                        </span>
                      </v-avatar>
                      <h3 class="mt-2">{{ $auth.user.nickname }}</h3>
                      <p class="text-caption mt-1">
                        {{ $auth.user.email }}
                      </p>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/my/profile">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>프로필</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/my/materials">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>나의 재료</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/my/galleries">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>나의 갤러리</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/my/communities">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>나의 커뮤니티</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/my/comments">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>나의 댓글</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link to="/my/changePassword">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>비밀번호 변경</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link @click.prevent="logout">
                  <v-list-item-content class="text-center">
                    <v-list-item-title>로그아웃</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-container>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isSticky: false,
      scrollPosition: 0,
      isMobile: false,
      showMenu: false,
    }
  },
  watch: {
    $route(to, from) {
      if (this.isMobile) {
        this.showMenu = false // 모바일 화면에서는 라우트 변경 시 메뉴를 숨김
      }
    },
  },
  mounted() {
    // 클라이언트에서만 아래 로직을 실행
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.checkViewport)
    this.checkViewport()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.checkViewport)
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.isSticky = this.scrollPosition > 70 && this.isMobile === false
      if (this.isSticky) {
        document.querySelector('header').classList.add('is-sticky')
      } else if (this.$route.path !== '/map') {
        document.querySelector('header').classList.remove('is-sticky')
      }
    },
    checkViewport() {
      this.isMobile = window.innerWidth < 768
      if (this.isMobile) {
        this.showMenu = false // 모바일 화면에서는 초기에는 메뉴를 숨김
      } else {
        this.showMenu = true // PC 화면에서는 항상 메뉴를 표시
      }
    },

    // 로그아웃
    async logout() {
      const vm = this
      await this.$confirm.showConfirm({
        show: true,
        title: '로그아웃 하시겠습니까?',
        yesTxt: '로그아웃',
        noTxt: '취소',
        yesFunc: () => {
          vm.$auth.logout()
          const intervalId = setInterval(() => {
            if (!vm.$auth.loggedIn) {
              clearInterval(intervalId)
              vm.$router.push({ path: '/' })
            }
          }, 500) // 500ms 간격으로 로그인 여부 확인
        },
      })
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
  },
}
</script>
<style lang="scss" scoped>
header {
  border-bottom: 1px solid $color__border-default;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  max-height: 80px;
  transition: height 0.3s ease;

  .hamburger {
    display: none;
  }

  .header-wrap {
    .site-nav {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    // padding: 18px 0;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #header-logo {
      display: block;
      width: 160px;
      height: auto;
      img {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      #header-logo {
        width: 120px;
      }
    }

    #gnb {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      transition: all 0.3s ease;
      li {
        a {
          font-size: 1.15rem;
          color: #000;
          font-weight: 800;
          letter-spacing: -1px;
          transition: all 0.3s ease;

          &:hover,
          &:active {
            color: $color__sig;
          }

          &.login {
            color: $color__sig;
          }

          &.register {
            font-size: 1rem;
            padding: 7px 15px;
            background: $color__sig;
            border-radius: 4px;
            color: #fff;
          }


          &.on:not(.login):not(.register) {
            color: $color__sig;
            &:after {
              content: '';
              display: block;
              border: 1px solid $color__sig;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .hamburger {
      display: block;
    }
    .header-wrap {
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      #gnb {
        // display: none;
        // border-top: 1px solid $color__border-default;
        border-bottom: 1px solid $color__border-default;
        flex-direction: column;
        gap: 0;
        left: 0;
        top: 60px;
        position: absolute;
        width: 100%;

        background-color: #fff;
        li {
          width: 100%;
          text-align: center;
          a {
            display: block;
            padding: 15px 0;
            text-align: center;

            &:not(.register) {
              width: 100%;
            }

            &.register {
              display: inline-block;
              font-size: 1rem;
              margin: 10px auto;
              border-radius: 4px;
            }

            &.on:not(.login):not(.register) {
              &:after {
                display: none;
              }
            }
          }
        }
        li + li {
          border-top: 1px solid $color__border-default;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.is-sticky {
      background-color: rgba(255, 255, 255, 0.75);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);

      .header-wrap {
        // padding: 10px 0;
        padding-top: 10px;
        padding-bottom: 10px;

        #header-logo {
          width: 140px;
        }

        #gnb {
          transition: height 0.5s ease-in-out;
          overflow: hidden;
          li {
            a {
              font-size: 1rem;

              &.register {
                font-size: 0.85rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
