// plugins/highlight.js
import Vue from 'vue'
import hljs from 'highlight.js'
import 'highlight.js/styles/github.css' // 선택한 스타일을 import 합니다.

Vue.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('pre code')
  blocks.forEach(block => {
    hljs.highlightBlock(block)
  })
})
