export default async ({ app, store, route, redirect }) => {
  const restrictedRoutes = ['login', 'forgetPassword', 'register']

  if (/^\/admin/i.test(route.path)) {
    if (app.$auth.loggedIn) {
      try {
        const response = await app.$axios.get('/auth/checkAdmin')

        if (response.data === true) {
          if (route.name === 'admin-login' || route.name === 'admin-recover' || route.name === 'admin') {
            return redirect('/admin/dashboard')
          }
        } else {
          return redirect('/')
        }
      } catch (error) {
        console.error(error)
        return redirect('/')
      }
    } else {
      return redirect('/admin/login')
    }
  }

  // 로그인된 상태에서 접근 금지 경로 확인
  if (app.$auth.loggedIn && restrictedRoutes.includes(route.name)) {
    return redirect('/')
  }
}
