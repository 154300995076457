// plugins/common.js
export default function (context, inject) {
  const formatPrice = value => {
    return new Intl.NumberFormat('ko-KR', {
      style: 'decimal',
      currency: 'KRW'
    }).format(value)
  }

  inject('formatPrice', formatPrice)

  const formatDate = value => {
    const now = new Date()
    const date = new Date(value)
    const diffTime = Math.abs(now - date)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) {
      // Calculate hours ago
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
      if (diffHours === 0) {
        const diffMinutes = Math.floor(diffTime / (1000 * 60))
        if (diffMinutes === 0) {
          return '방금 전'
        } else {
          return `${diffMinutes}분 전`
        }
      } else {
        return `${diffHours}시간 전`
      }
    } else if (diffDays <= 7) {
      return `${diffDays}일 전`
    } else {
      return date.toLocaleDateString()
    }
  }

  inject('formatDate', formatDate)

  const nl2br = text => {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
  }

  inject('nl2br', nl2br)
}
