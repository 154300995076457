const middleware = {}

middleware['admin/auth'] = require('../middleware/admin/auth.js')
middleware['admin/auth'] = middleware['admin/auth'].default || middleware['admin/auth']

middleware['admin/layout'] = require('../middleware/admin/layout.js')
middleware['admin/layout'] = middleware['admin/layout'].default || middleware['admin/layout']

middleware['checkOwner'] = require('../middleware/checkOwner.js')
middleware['checkOwner'] = middleware['checkOwner'].default || middleware['checkOwner']

middleware['checkRootPath'] = require('../middleware/checkRootPath.js')
middleware['checkRootPath'] = middleware['checkRootPath'].default || middleware['checkRootPath']

middleware['userLoggedIn'] = require('../middleware/userLoggedIn.js')
middleware['userLoggedIn'] = middleware['userLoggedIn'].default || middleware['userLoggedIn']

middleware['userNotLoggedIn'] = require('../middleware/userNotLoggedIn.js')
middleware['userNotLoggedIn'] = middleware['userNotLoggedIn'].default || middleware['userNotLoggedIn']

export default middleware
