export const state = () => ({
  navItems: [
    {
      divide: true,
      title: '대시보드',
      icon: 'mdi-view-dashboard-outline',
      to: '/admin/dashboard'
    },

    {
      divide: true,
      title: '환경설정',
      icon: 'mdi-cog-outline',
      // to: '/admin/settings'
      subItems: [
        // {
        //   title: '기본정보 설정',
        //   to: '/admin/settings/default'
        // },
        {
          title: 'API 설정',
          to: '/admin/settings/api'
        }
      ]
    },
    {
      title: '관리자',
      icon: 'mdi-shield-account-outline',
      subItems: [
        {
          title: '관리자 목록',
          to: '/admin/managers/list'
        },
        {
          title: '관리자 등록',
          to: '/admin/managers/create'
        }
      ]
    },
    {
      divide: true,
      title: '회원',
      icon: 'mdi-account-group-outline',
      subItems: [
        {
          title: '회원 목록',
          to: '/admin/users/list'
        }
      ]
    },
    {
      title: '재료',
      icon: 'mdi-wall',
      subItems: [
        {
          title: '등록된 재료 목록',
          to: '/admin/materials/list'
        },
        {
          hidden: true,
          title: '상세 보기',
          to: '/admin/materials/{id}'
        }
      ]
    },
    {
      title: '업사이클 갤러리',
      icon: 'mdi-image-multiple-outline',
      subItems: [
        {
          title: '업사이클 갤러리 포스트 목록',
          to: '/admin/upcycleGallery/list'
        },
        {
          // hidden: true,
          title: '업사이클 갤러리 포스트 등록',
          to: '/admin/upcycleGallery/create'
        },
        {
          hidden: true,
          title: '업사이클 갤러리 포스트 상세보기',
          to: '/admin/upcycleGallery/{id}'
        },
        {
          hidden: true,
          title: '업사이클 갤러리 포스트 수정',
          to: '/admin/upcycleGallery/{id}/edit'
        }
      ]
    },
    // {
    //   title: '게시판',
    //   icon: 'mdi-post-outline',
    //   subItems: [
    //     {
    //       title: '게시판 목록',
    //       to: '/admin/bbs/list'
    //     },
    //     {
    //       title: '게시판 등록',
    //       to: '/admin/bbs/create'
    //     },
    //     {
    //       title: '전체 글 목록',
    //       to: '/admin/article/list'
    //     },
    //     {
    //       title: '전체 댓글 목록',
    //       to: '/admin/article/comments'
    //     }
    //   ]
    // },
    {
      title: '공지사항',
      icon: 'mdi-bell-outline',
      subItems: [
        {
          title: '공지사항 목록',
          to: '/admin/notice/list'
        },
        {
          title: '공지사항 등록',
          to: '/admin/notice/create'
        },
        {
          hidden: true,
          title: '공지사항 상세보기',
          to: '/admin/notice/{id}'
        },
        {
          hidden: true,
          title: '공지사항 수정',
          to: '/admin/notice/{id}/edit'
        }
      ]
    },
    {
      title: '커뮤니티',
      icon: 'mdi-forum-outline',
      subItems: [
        {
          title: '커뮤니티 글 목록',
          to: '/admin/community/list'
        },
        {
          title: '커뮤니티 글 등록',
          to: '/admin/community/create'
        },
        {
          hidden: true,
          title: '커뮤니티 글 상세보기',
          to: '/admin/community/{id}'
        },
        {
          hidden: true,
          title: '커뮤니티 글 수정',
          to: '/admin/community/{id}/edit'
        }
      ]
    },

    {
      gnbHidden: true,
      title: '사용자',
      subItems: [
        {
          title: '프로필',
          to: '/admin/my/profile'
        }
      ]
    }
  ],
  pageTitles: [
    {
      to: '/admin/managers/show',
      title: '관리자 상세보기'
    },
    {
      to: '/admin/managers/update',
      title: '관리자 수정'
    },
    {
      to: '/admin/users/show',
      title: '회원 상세보기'
    },
    {
      to: '/admin/users/update',
      title: '회원 수정'
    },
    {
      to: '/admin/my/profile/edit',
      title: '프로필 수정'
    }
  ],

  current_title: ''
})

export const mutations = {
  CHANGE_NAV_TRANS (state, payload) {
    state.navItems = payload.nav
  },
  SET_CURRENT_TITLE (state, title) {
    state.current_title = title
  },
  CHANGE_CURRENT_PATH (state, payload) {
    state.current_path = payload.current_path
  }
}

export const actions = {
  changeNavTrans ({ commit }, payload) {
    commit('CHANGE_NAV_TRANS', payload)
  },

  setCurrentPath ({ commit }, payload) {
    commit('CHANGE_CURRENT_PATH', payload)
  },

  setPageTitle ({ commit, state }, payload) {
    const deepFind =
      pred =>
      ([x, ...xs] = []) =>
        x && (pred(x) ? x : deepFind(pred)(x.subItems) || deepFind(pred)(xs))

    const findTitle = to => obj =>
      deepFind(o => {
        if (!o) return false
        const regex = /{id}/
        const normalizedTo = regex.test(o.to) ? o.to.replace(regex, '\\d+') : o.to
        const regexTo = new RegExp(`^${normalizedTo}$`)
        return regexTo.test(to)
      })(obj)

    const navItem = state.navItems
    const obj = findTitle(payload)(navItem)

    let title = ''
    if (!obj) {
      title = state.pageTitles.find(e => e.to === payload).title
    } else if (obj.title) {
      title = obj.title
    } else {
      title = payload
    }

    commit('SET_CURRENT_TITLE', title)
  }
}
