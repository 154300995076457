export const state = () => ({
  map: null,
  lat: null,
  lng: null,
})

export const mutations = {
  setMap(state, payload) {
    state.map = payload
  },
  setLat(state, payload) {
    state.lat = payload
  },
  setLng(state, payload) {
    state.lng = payload
  },
}

export const actions = {
  updateMap({ commit }, payload) {
    commit('setMap', payload)
  },
  updateLat({ commit }, payload) {
    commit('setLat', payload)
  },
  updateLng({ commit }, payload) {
    commit('setLng', payload)
  },
}

export const getters = {
  getMap: (state) => {
    return state.map
  },
  getLat: (state) => {
    return state.lat
  },
  getLng: (state) => {
    return state.lng
  },
}
