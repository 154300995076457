<template>
  <footer>
    <v-container>
      <div class="footer-top">
        <div class="logo">
          <img src="/images/footer-logo@2x.png" alt="재료공장" />
        </div>
        <ul class="el footer-menu">
          <!-- <li><NuxtLink to="notice">공지사항</NuxtLink></li> -->
          <!-- <li><NuxtLink to="notice">개인정보취급방침</NuxtLink></li> -->
          <li><a href="mailto:refactory.co.kr@gmail.com">이메일문의</a></li>
        </ul>
        <div class="social-links">
          <a href="https://facebook.com" target="_blank">
            <img src="/images/facebook.png" alt="페이스북으로 가기" />
          </a>
          <a href="https://twitter.com" target="_blank">
            <img src="/images/twitter.png" alt="페이스북으로 가기" />
          </a>
          <a href="https://instagram.com" target="_blank">
            <img src="/images/instagram.png" alt="페이스북으로 가기" />
          </a>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="copyright">
          &copy;
          {{ new Date().getFullYear() == '2023' ? '2023' : '2023~' + new Date().getFullYear() }}
          Refactory. All rights reserved.
        </div>
      </div>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
}
</script>
<style lang="scss" scoped>
footer {
  background-color: #f3f3f4;
  padding: 30px 0;
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 140px;
    }
    .footer-menu {
      li {
        font-size: 0.85rem;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        padding-right: 10px;

        &:after {
          content: '/';
          position: absolute;
          right: -10px;
        }
        &:last-child {
          margin-right: 0;
          padding-right: 0;
          &:after {
            content: none;
          }
        }
      }
    }

    .social-links {
      font-size: 0;
      a {
        display: inline-block;
      }

      a + a {
        margin-left: 15px;
      }
    }
  }

  .footer-bottom {
    margin-top: 10px;
    .copyright {
      font-size: 0.85rem;
    }
  }

  &.is-sticky {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    backdrop-filter: blur(5px);

    .footer-top {
      .logo {
        width: 120px;
      }

      .footer-menu {
        li {
          font-size: 0.75rem;
        }
      }

      .social-links {
        a {
          width: 30px;
        }
      }
    }

    .footer-bottom {
      margin-top: 5px;
      .copyright {
        font-size: 0.65rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  footer {
    padding: 20px 0;
    .footer-top {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .logo {
        width: 100px;
      }

      // .footer-menu {
      //   li {
      //     font-size: 0.85rem;
      //     display: inline-block;
      //     position: relative;
      //     margin-right: 20px;
      //     padding-right: 10px;

      //     &:after {
      //       content: '/';
      //       position: absolute;
      //       right: -10px;
      //     }
      //     &:last-child {
      //       margin-right: 0;
      //       padding-right: 0;
      //       &:after {
      //         content: none;
      //       }
      //     }
      //   }
      // }

      .social-links {
        font-size: 0;
        a {
          display: inline-block;
          width: 25px;
          height: 25px;
        }

        a + a {
          margin-left: 15px;
        }
      }
    }

    .footer-bottom {
      margin-top: 20px;

      .copyright {
        text-align: center;
        font-size: 0.85rem;
      }
    }
  }
}
</style>
