<template>
  <v-app>
    <Nuxt />
    <Snackbar></Snackbar>
    <Confirm></Confirm>
  </v-app>
</template>

<script>
import Snackbar from '~/components/_common/Snackbar.vue'
import Confirm from '~/components/_common/Confirm.vue'
export default {
  components: { Snackbar, Confirm },
}
</script>
