export default ({ $axios, redirect, app }, inject) => {
  const exceptPath = ['/admin/login', '/admin/user', '/mypage/index', '/material/create']
  let currentPath

  if (process.client) {
    currentPath = window.location.pathname
  }

  $axios.onError(error => {
    console.log('error', error)
    // if (error.status === false) {
    // Display a flash notification
    // if (!exceptPath.includes(currentPath)) {
      app.$snackbar.showMessage({
        content: error.response.data.message,
        color: 'red darken-4',
        delay: 3000
      })
      // }
      throw error
    // }
  })

  $axios.onResponse(response => {
    if (response.status === 200 && !exceptPath.includes(currentPath) && response.data.message && !response.data.body) {
      app.$snackbar.showMessage({
        content: response.data.message,
        color: 'green darken-1',
        delay: 3000
      })
    }
  })
}
