import { render, staticRenderFns } from "./error__.vue?vue&type=template&id=78f6e044&scoped=true&"
import script from "./error__.vue?vue&type=script&lang=js&"
export * from "./error__.vue?vue&type=script&lang=js&"
import style0 from "./error__.vue?vue&type=style&index=0&id=78f6e044&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f6e044",
  null
  
)

export default component.exports