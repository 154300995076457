export default ({ app, store }, inject) => {
  inject('confirm', {
    showConfirm({
      show = false,
      title = '',
      message = '',
      yesTxt = '',
      noTxt = '',
      yesColor = 'primary',
      noColor = 'default',
      yesFunc = '',
      noFunc = '',
      persistent = false,
      width = 350,
    }) {
      store.commit('confirm/showConfirm', {
        show,
        title,
        message,
        yesTxt,
        noTxt,
        yesColor,
        noColor,
        yesFunc,
        noFunc,
        persistent,
        width,
      })
    },
  })
}
