export const state = () => ({
  content: null,
})

export const mutations = {
  setContent(state, content) {
    state.content = content
  },
}

export const actions = {
  saveEditorContent({ commit }, content) {
    commit('setContent', content)
  },
}
