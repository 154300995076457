<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-app class="admin-page">
    <v-app-bar app elevate-on-scroll clipped-left>
      <div class="d-flex align-center flex-grow-1 v-card v-card--flat v-sheet">
        <v-btn icon class="hidden-md-and-down" @click.stop="changeDrawer">
          <v-icon
            >mdi-{{
              `${
                $store.state.admin.layout.eoh ? 'chevron-right' : 'chevron-left'
              }`
            }}</v-icon
          >
        </v-btn>

        <v-btn icon class="hidden-lg-and-up" @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-toolbar-title>재료공장 관리자페이지</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <IconButton
          icon="mdi-home"
          tooltip-text=""
          target="_blank"
          @click="$router.push(`/`)"
        ></IconButton> -->
        <v-btn icon :to="{ path: '/', query: {} }" target="_blank">
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn icon @click.stop="toggleDarkMode">
          <v-icon
            >mdi-{{
              `${
                $store.state.admin.layout.darkmode
                  ? 'weather-night'
                  : 'white-balance-sunny'
              }`
            }}</v-icon
          >
        </v-btn>

        <v-menu bottom min-width="200px" rounded offset-y>
          <template #activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="primary" size="36">
                <img
                  v-if="$auth.user.avatar !== '' && $auth.user.avatar"
                  alt="Avatar"
                  :src="$auth.user.avatar"
                />
                <span v-else class="white--text text-h6">{{
                  user.nickname.split('')[0] ? user.nickname.split('')[0] : ''
                }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="primary" size="72">
                  <img
                    v-if="$auth.user.avatar !== '' && $auth.user.avatar"
                    alt="Avatar"
                    :src="$auth.user.avatar"
                  />
                  <span v-else class="white--text text-h5">{{
                    user.nickname.split('')[0] ? user.nickname.split('')[0] : ''
                  }}</span>
                </v-avatar>
                <h3 class="mt-2">{{ $auth.user.nickname }}</h3>
                <p class="text-caption mt-1">
                  {{ $auth.user.email }}
                </p>
                <v-divider class="my-1"></v-divider>
                <v-btn depressed rounded text to="/admin/my/profile">
                  프로필
                </v-btn>
                <v-divider class="my-1"></v-divider>
                <v-btn depressed rounded text @click.prevent="logout">
                  로그아웃
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="$store.state.admin.layout.eoh"
      :mini-variant="$store.state.admin.layout.mini"
      app
      clipped
    >
      <template #prepend>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                최근 로그인 : 2021-10-05 12:02:00</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider class="mb-1" />

      <v-list dense subheader nav>
        <template v-for="(item, index) in navItems">
          <v-list-item v-if="!item.subItems" :key="index" :to="item.to">
            <v-list-item-icon class="mr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else-if="!item.gnbHidden"
            :key="item.title"
            :prepend-icon="item.icon"
          >
            {{ item.to }}
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in filteredSubItems(item.subItems)"
              :key="subItem.title"
              :to="subItem.to"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>
                  {{ subItem.icon ? subItem.icon : 'mdi-circle-small' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-divider
            v-if="item.divide === true"
            :key="item.index"
            class="my-1"
          />
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-row class="align-center justify-space-between">
          <v-col>
            <v-container fluid>
              <v-row>
                <v-col>
                  <h2>{{ $store.state.admin.nav.current_title }}</h2>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <!-- If using vue-router -->
        <!-- <router-view /> -->
        <Nuxt />
      </v-container>
    </v-main>

    <v-footer app>
      Copyright &copy;
      {{
        new Date().getFullYear() == '2023'
          ? '2023'
          : '2023~' + new Date().getFullYear()
      }}
      Refactory.
    </v-footer>
    <Snackbar></Snackbar>
    <Confirm></Confirm>
  </v-app>
</template>

<script>
import Snackbar from '~/components/_common/Snackbar.vue'
import Confirm from '~/components/_common/Confirm.vue'

export default {
  // middleware: ['admin/auth'],
  name: 'DefaultLayout',
  components: { Snackbar, Confirm },

  data() {
    return {
      title: '관리자페이지',
      date: 'haha',
      darkswitch: this.$store.state.admin.layout.darkmode,
      drawer: false,
      navItems: this.$store.state.admin.nav.navItems,
      user: '',
    }
  },
  head() {
    return {
      title: this.title,
    }
  },
  // computed: {
  //   getCurrentTitle () {
  //     return this.$store.getters['admin/nav/findCurrentTitle']
  //   }
  // },
  computed: {
    filteredSubItems() {
      return (subItems) => {
        return subItems.filter((subItem) => !subItem.hidden)
      }
    },
  },
  watch: {
    $route(to, from) {
      this.setPageTItle()
    },
  },
  mounted() {
    this.onResize()
    this.setPageTItle()

    if (window.innerWidth >= 1264) {
      this.drawer = true
    }
  },
  created() {
    // 다크모드
    this.$vuetify.theme.dark = this.$store.state.admin.layout.darkmode

    const currentUser = { ...this.$auth.user }
    currentUser.nickname = currentUser.nickname
      ? currentUser.nickname
      : currentUser.name
    this.$auth.setUser(currentUser)
    this.user = currentUser
  },
  methods: {
    confirmLogout() {
      console.log('zzz')
    },
    onResize() {
      // 리사이즈 시 dwawer 펼침상태로 변경
      window.addEventListener('resize', () => {
        if (window.innerWidth < 1264) {
          this.$store.dispatch('admin/layout/setMobile')
        }
      })
    },

    // 로그아웃
    async logout() {
      const vm = this
      await this.$confirm.showConfirm({
        show: true,
        title: '로그아웃 하시겠습니까?',
        yesTxt: '로그아웃',
        noTxt: '취소',
        yesFunc: () => {
          vm.$auth.logout()
          const intervalId = setInterval(() => {
            if (!vm.$auth.loggedIn) {
              clearInterval(intervalId)
              vm.$router.push({ path: '/' })
            }
          }, 500) // 500ms 간격으로 로그인 여부 확인
        },
      })
    },
    // 메뉴 접기
    changeDrawer() {
      this.$store.dispatch('admin/layout/toggleMini')
    },
    // 다크 모드
    toggleDarkMode() {
      this.$store.dispatch('admin/layout/toggleDarkMode')
    },
    // 현재 메뉴 타이틀 구하기
    setPageTItle() {
      this.$store.dispatch('admin/nav/setPageTitle', this.$route.path)
    },

    // findCurrentTitle (data, to) {
    //   function iter (a) {
    //     if (a.to === to) {
    //       result = a
    //       return true
    //     }
    //     if (a.titles) {
    //       return Array.isArray(a.titles) && a.titles.some(iter)
    //     } else {
    //       return Array.isArray(a.subItems) && a.subItems.some(iter)
    //     }
    //   }

    //   let result
    //   data.some(iter)
    //   return result
    // },
    // changeCurrentTitle () {
    //   this.$store.dispatch('admin/nav/changeCurrentTitle', {
    //     current_title: this.findCurrentTitle(
    //       this.$store.state.admin.nav.navItems,
    //       this.$route.path
    //     ).title
    //   })
    // }
    // yes () {
    //   console.log('yes')
    // },
    // no () {
    //   console.log('no')
    // }
  },
}
</script>
<style lang="scss">
.admin-page {
  .v-list-group__header__prepend-icon {
    margin-right: 12px !important;
  }

  &.theme--light {
    header {
      background-color: #8ec5fc;
      background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
    }
  }

  &.theme--dark {
    header {
      background-color: #008dad;
      background-image: linear-gradient(62deg, #008dad 0%, #b721ff 100%);
    }
  }

  footer {
    justify-content: center;
    font-family: 'roboto';
    font-weight: 600;
    font-size: 0.8rem;
  }
}
</style>
