import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6de69c42 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _d2a77748 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _86039ab0 = () => interopDefault(import('../pages/forgetPassword.vue' /* webpackChunkName: "pages/forgetPassword" */))
const _63127d0a = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _3e096019 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d10eaae8 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _344d17ea = () => interopDefault(import('../pages/material/index.vue' /* webpackChunkName: "pages/material/index" */))
const _5fe09a5b = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _03571623 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _b1b5c58c = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _2bb82b2c = () => interopDefault(import('../pages/upcycleGallery/index.vue' /* webpackChunkName: "pages/upcycleGallery/index" */))
const _74db0104 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _378e3f59 = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _71905ffc = () => interopDefault(import('../pages/community/categories.vue' /* webpackChunkName: "pages/community/categories" */))
const _669091e2 = () => interopDefault(import('../pages/community/create.vue' /* webpackChunkName: "pages/community/create" */))
const _7d0a3014 = () => interopDefault(import('../pages/material/create.vue' /* webpackChunkName: "pages/material/create" */))
const _749e4b5e = () => interopDefault(import('../pages/my/changePassword.vue' /* webpackChunkName: "pages/my/changePassword" */))
const _66bd0ab2 = () => interopDefault(import('../pages/my/comments.vue' /* webpackChunkName: "pages/my/comments" */))
const _f2d4b938 = () => interopDefault(import('../pages/my/communities.vue' /* webpackChunkName: "pages/my/communities" */))
const _5bc69566 = () => interopDefault(import('../pages/my/galleries.vue' /* webpackChunkName: "pages/my/galleries" */))
const _b0bd07ee = () => interopDefault(import('../pages/my/materials.vue' /* webpackChunkName: "pages/my/materials" */))
const _411ccc46 = () => interopDefault(import('../pages/my/profile.vue' /* webpackChunkName: "pages/my/profile" */))
const _5d946163 = () => interopDefault(import('../pages/notice/categories.vue' /* webpackChunkName: "pages/notice/categories" */))
const _7832087a = () => interopDefault(import('../pages/notice/create.vue' /* webpackChunkName: "pages/notice/create" */))
const _fbe4a4d8 = () => interopDefault(import('../pages/upcycleGallery/create.vue' /* webpackChunkName: "pages/upcycleGallery/create" */))
const _ec1eee7c = () => interopDefault(import('../pages/admin/community/categories.vue' /* webpackChunkName: "pages/admin/community/categories" */))
const _83f8aabc = () => interopDefault(import('../pages/admin/community/create.vue' /* webpackChunkName: "pages/admin/community/create" */))
const _b8bbf678 = () => interopDefault(import('../pages/admin/community/list.vue' /* webpackChunkName: "pages/admin/community/list" */))
const _10d890b5 = () => interopDefault(import('../pages/admin/managers/create.vue' /* webpackChunkName: "pages/admin/managers/create" */))
const _7c8f5397 = () => interopDefault(import('../pages/admin/managers/list.vue' /* webpackChunkName: "pages/admin/managers/list" */))
const _18283676 = () => interopDefault(import('../pages/admin/managers/show.vue' /* webpackChunkName: "pages/admin/managers/show" */))
const _a4cf0bfc = () => interopDefault(import('../pages/admin/managers/update.vue' /* webpackChunkName: "pages/admin/managers/update" */))
const _24b0ebc2 = () => interopDefault(import('../pages/admin/materials/categories.vue' /* webpackChunkName: "pages/admin/materials/categories" */))
const _4e83e27f = () => interopDefault(import('../pages/admin/materials/create.vue' /* webpackChunkName: "pages/admin/materials/create" */))
const _57988fe1 = () => interopDefault(import('../pages/admin/materials/list.vue' /* webpackChunkName: "pages/admin/materials/list" */))
const _dffd15f4 = () => interopDefault(import('../pages/admin/my/profile.vue' /* webpackChunkName: "pages/admin/my/profile" */))
const _16f73eba = () => interopDefault(import('../pages/admin/notice/categories.vue' /* webpackChunkName: "pages/admin/notice/categories" */))
const _b18ec9fa = () => interopDefault(import('../pages/admin/notice/create.vue' /* webpackChunkName: "pages/admin/notice/create" */))
const _bf2ccd36 = () => interopDefault(import('../pages/admin/notice/list.vue' /* webpackChunkName: "pages/admin/notice/list" */))
const _34ecf8ee = () => interopDefault(import('../pages/admin/settings/api.vue' /* webpackChunkName: "pages/admin/settings/api" */))
const _045672f3 = () => interopDefault(import('../pages/admin/settings/defalut_back.vue' /* webpackChunkName: "pages/admin/settings/defalut_back" */))
const _f7f028d6 = () => interopDefault(import('../pages/admin/settings/default.vue' /* webpackChunkName: "pages/admin/settings/default" */))
const _2563c724 = () => interopDefault(import('../pages/admin/settings/test.vue' /* webpackChunkName: "pages/admin/settings/test" */))
const _700cb77b = () => interopDefault(import('../pages/admin/upcycle-gallery/create.vue' /* webpackChunkName: "pages/admin/upcycle-gallery/create" */))
const _4e0a73dd = () => interopDefault(import('../pages/admin/upcycle-gallery/list.vue' /* webpackChunkName: "pages/admin/upcycle-gallery/list" */))
const _166c0cd4 = () => interopDefault(import('../pages/admin/upcycleGallery/create.vue' /* webpackChunkName: "pages/admin/upcycleGallery/create" */))
const _46c14b76 = () => interopDefault(import('../pages/admin/upcycleGallery/list.vue' /* webpackChunkName: "pages/admin/upcycleGallery/list" */))
const _3059ed85 = () => interopDefault(import('../pages/admin/users/list.vue' /* webpackChunkName: "pages/admin/users/list" */))
const _fbdc985e = () => interopDefault(import('../pages/admin/oauth/google/getToken.vue' /* webpackChunkName: "pages/admin/oauth/google/getToken" */))
const _35c10087 = () => interopDefault(import('../pages/admin/community/_id/index.vue' /* webpackChunkName: "pages/admin/community/_id/index" */))
const _22b440ec = () => interopDefault(import('../pages/admin/materials/_id/index.vue' /* webpackChunkName: "pages/admin/materials/_id/index" */))
const _855baef4 = () => interopDefault(import('../pages/admin/notice/_id/index.vue' /* webpackChunkName: "pages/admin/notice/_id/index" */))
const _50fc1915 = () => interopDefault(import('../pages/admin/upcycleGallery/_id/index.vue' /* webpackChunkName: "pages/admin/upcycleGallery/_id/index" */))
const _20a575e5 = () => interopDefault(import('../pages/admin/community/_id/edit.vue' /* webpackChunkName: "pages/admin/community/_id/edit" */))
const _df405bf4 = () => interopDefault(import('../pages/admin/notice/_id/edit.vue' /* webpackChunkName: "pages/admin/notice/_id/edit" */))
const _007e1397 = () => interopDefault(import('../pages/admin/upcycleGallery/_id/edit.vue' /* webpackChunkName: "pages/admin/upcycleGallery/_id/edit" */))
const _ca585d72 = () => interopDefault(import('../pages/community/_id/index.vue' /* webpackChunkName: "pages/community/_id/index" */))
const _0da42dd5 = () => interopDefault(import('../pages/material/_id/index.vue' /* webpackChunkName: "pages/material/_id/index" */))
const _65df0fc6 = () => interopDefault(import('../pages/notice/_id/index.vue' /* webpackChunkName: "pages/notice/_id/index" */))
const _7ecdc055 = () => interopDefault(import('../pages/upcycleGallery/_id/index.vue' /* webpackChunkName: "pages/upcycleGallery/_id/index" */))
const _4cd4e5b6 = () => interopDefault(import('../pages/community/_id/edit.vue' /* webpackChunkName: "pages/community/_id/edit" */))
const _382066d7 = () => interopDefault(import('../pages/material/_id/edit.vue' /* webpackChunkName: "pages/material/_id/edit" */))
const _0a3a8457 = () => interopDefault(import('../pages/upcycleGallery/_id/edit.vue' /* webpackChunkName: "pages/upcycleGallery/_id/edit" */))
const _7461bd02 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _6de69c42,
    name: "admin"
  }, {
    path: "/community",
    component: _d2a77748,
    name: "community"
  }, {
    path: "/forgetPassword",
    component: _86039ab0,
    name: "forgetPassword"
  }, {
    path: "/inspire",
    component: _63127d0a,
    name: "inspire"
  }, {
    path: "/login",
    component: _3e096019,
    name: "login"
  }, {
    path: "/map",
    component: _d10eaae8,
    name: "map"
  }, {
    path: "/material",
    component: _344d17ea,
    name: "material"
  }, {
    path: "/notice",
    component: _5fe09a5b,
    name: "notice"
  }, {
    path: "/register",
    component: _03571623,
    name: "register"
  }, {
    path: "/resetPassword",
    component: _b1b5c58c,
    name: "resetPassword"
  }, {
    path: "/upcycleGallery",
    component: _2bb82b2c,
    name: "upcycleGallery"
  }, {
    path: "/admin/dashboard",
    component: _74db0104,
    name: "admin-dashboard"
  }, {
    path: "/admin/login",
    component: _378e3f59,
    name: "admin-login"
  }, {
    path: "/community/categories",
    component: _71905ffc,
    name: "community-categories"
  }, {
    path: "/community/create",
    component: _669091e2,
    name: "community-create"
  }, {
    path: "/material/create",
    component: _7d0a3014,
    name: "material-create"
  }, {
    path: "/my/changePassword",
    component: _749e4b5e,
    name: "my-changePassword"
  }, {
    path: "/my/comments",
    component: _66bd0ab2,
    name: "my-comments"
  }, {
    path: "/my/communities",
    component: _f2d4b938,
    name: "my-communities"
  }, {
    path: "/my/galleries",
    component: _5bc69566,
    name: "my-galleries"
  }, {
    path: "/my/materials",
    component: _b0bd07ee,
    name: "my-materials"
  }, {
    path: "/my/profile",
    component: _411ccc46,
    name: "my-profile"
  }, {
    path: "/notice/categories",
    component: _5d946163,
    name: "notice-categories"
  }, {
    path: "/notice/create",
    component: _7832087a,
    name: "notice-create"
  }, {
    path: "/upcycleGallery/create",
    component: _fbe4a4d8,
    name: "upcycleGallery-create"
  }, {
    path: "/admin/community/categories",
    component: _ec1eee7c,
    name: "admin-community-categories"
  }, {
    path: "/admin/community/create",
    component: _83f8aabc,
    name: "admin-community-create"
  }, {
    path: "/admin/community/list",
    component: _b8bbf678,
    name: "admin-community-list"
  }, {
    path: "/admin/managers/create",
    component: _10d890b5,
    name: "admin-managers-create"
  }, {
    path: "/admin/managers/list",
    component: _7c8f5397,
    name: "admin-managers-list"
  }, {
    path: "/admin/managers/show",
    component: _18283676,
    name: "admin-managers-show"
  }, {
    path: "/admin/managers/update",
    component: _a4cf0bfc,
    name: "admin-managers-update"
  }, {
    path: "/admin/materials/categories",
    component: _24b0ebc2,
    name: "admin-materials-categories"
  }, {
    path: "/admin/materials/create",
    component: _4e83e27f,
    name: "admin-materials-create"
  }, {
    path: "/admin/materials/list",
    component: _57988fe1,
    name: "admin-materials-list"
  }, {
    path: "/admin/my/profile",
    component: _dffd15f4,
    name: "admin-my-profile"
  }, {
    path: "/admin/notice/categories",
    component: _16f73eba,
    name: "admin-notice-categories"
  }, {
    path: "/admin/notice/create",
    component: _b18ec9fa,
    name: "admin-notice-create"
  }, {
    path: "/admin/notice/list",
    component: _bf2ccd36,
    name: "admin-notice-list"
  }, {
    path: "/admin/settings/api",
    component: _34ecf8ee,
    name: "admin-settings-api"
  }, {
    path: "/admin/settings/defalut_back",
    component: _045672f3,
    name: "admin-settings-defalut_back"
  }, {
    path: "/admin/settings/default",
    component: _f7f028d6,
    name: "admin-settings-default"
  }, {
    path: "/admin/settings/test",
    component: _2563c724,
    name: "admin-settings-test"
  }, {
    path: "/admin/upcycle-gallery/create",
    component: _700cb77b,
    name: "admin-upcycle-gallery-create"
  }, {
    path: "/admin/upcycle-gallery/list",
    component: _4e0a73dd,
    name: "admin-upcycle-gallery-list"
  }, {
    path: "/admin/upcycleGallery/create",
    component: _166c0cd4,
    name: "admin-upcycleGallery-create"
  }, {
    path: "/admin/upcycleGallery/list",
    component: _46c14b76,
    name: "admin-upcycleGallery-list"
  }, {
    path: "/admin/users/list",
    component: _3059ed85,
    name: "admin-users-list"
  }, {
    path: "/admin/oauth/google/getToken",
    component: _fbdc985e,
    name: "admin-oauth-google-getToken"
  }, {
    path: "/admin/community/:id",
    component: _35c10087,
    name: "admin-community-id"
  }, {
    path: "/admin/materials/:id",
    component: _22b440ec,
    name: "admin-materials-id"
  }, {
    path: "/admin/notice/:id",
    component: _855baef4,
    name: "admin-notice-id"
  }, {
    path: "/admin/upcycleGallery/:id",
    component: _50fc1915,
    name: "admin-upcycleGallery-id"
  }, {
    path: "/admin/community/:id?/edit",
    component: _20a575e5,
    name: "admin-community-id-edit"
  }, {
    path: "/admin/notice/:id?/edit",
    component: _df405bf4,
    name: "admin-notice-id-edit"
  }, {
    path: "/admin/upcycleGallery/:id?/edit",
    component: _007e1397,
    name: "admin-upcycleGallery-id-edit"
  }, {
    path: "/community/:id",
    component: _ca585d72,
    name: "community-id"
  }, {
    path: "/material/:id",
    component: _0da42dd5,
    name: "material-id"
  }, {
    path: "/notice/:id",
    component: _65df0fc6,
    name: "notice-id"
  }, {
    path: "/upcycleGallery/:id",
    component: _7ecdc055,
    name: "upcycleGallery-id"
  }, {
    path: "/community/:id/edit",
    component: _4cd4e5b6,
    name: "community-id-edit"
  }, {
    path: "/material/:id/edit",
    component: _382066d7,
    name: "material-id-edit"
  }, {
    path: "/upcycleGallery/:id/edit",
    component: _0a3a8457,
    name: "upcycleGallery-id-edit"
  }, {
    path: "/",
    component: _7461bd02,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
