import EditorJSParser from 'editorjs-parser'

export default (context, inject) => {
  const customParsers = {
    linkTool: data => {
      const {
        link,
        meta: { title, description, image: { url = '' } = {} }
      } = data

      // 이미지가 없을 때 .preview-thumbnail을 숨김
      const thumbnailStyle = url ? '' : 'display: none;'

      return `
        <div class="link-preview">
          <a href="${link}" target="_blank">
            <div class="preview-thumbnail" style="${thumbnailStyle}">
              <img src="${url}" alt="${title || description}">
            </div>
            <div class="preview-info">
              <h4 class="preview-title">${title}</h4>
              <p class="preview-description">${description}</p>
            </div>
          </a>
        </div>`
    },
    // attaches: data => `<a href="${data.file.url}" download="${data.file.name}">${data.file.name}</a>`
    attaches: data => `
      <div class="cdx-attaches cdx-attaches--with-file">
        <div class="cdx-attaches__file-icon">
          <div class="cdx-attaches__file-icon-background" style="background-color: rgb(79, 86, 111);"></div>
          <div class="cdx-attaches__file-icon-label" title="${data.file.extension}" style="background-color: rgb(79, 86, 111);">${data.file.extension}</div>
        </div>
        <div class="cdx-attaches__file-info">
          <div class="cdx-attaches__title" contenteditable="true" data-placeholder="File title">${data.file.name}</div>
          <div class="cdx-attaches__size" data-size="MiB">${(data.file.size / (1024 * 1024)).toFixed(1)}</div>
        </div>
        <a class="cdx-attaches__download-button text-subtitle-2" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer">
          다운로드
        </a>
      </div>
    `,
    table: data => {
      let html = '<table>'
      data.content.forEach((row, index) => {
        if (data.withHeadings && index === 0) {
          html += '<thead><tr>'
          row.forEach(cell => {
            html += `<th>${cell}</th>`
          })
          html += '</tr></thead>'
        } else {
          html += index === 1 ? '<tbody><tr>' : '<tr>'
          row.forEach(cell => {
            html += `<td>${cell}</td>`
          })
          html += '</tr>'
          html += index === data.content.length - 1 ? '</tbody>' : ''
        }
      })
      html += '</table>'
      return html
    }
  }

  const editorParser = new EditorJSParser(undefined, customParsers)

  inject('editorParser', editorParser)
}
