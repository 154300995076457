export const state = () => ({
  categories: {},
  categoriesOriginal: {}
})

export const mutations = {
  SET_CATEGORIES (state, payload) {
    state.categories = payload
    state.categoriesOriginal = payload
  },

  PUSH_CATEGORY (state, payload) {
    console.log(payload)
    state.categories.push(payload)
    state.categoriesOriginal.push(payload)
  },
  SET_EDITABLE (state, payload) {
    Object.entries(state.categories).forEach(([i, e]) => {
      if (e.id === payload.id) {
        state.categories[i].editing = true
      }
    })
  },
  CANCEL_EDITABLE (state, payload) {
    Object.entries(state.categories).forEach(([i, e]) => {
      if (e.id === payload.id) {
        // console.log(state.categoriesOriginal[i].name)
        // console.log(state.categories[i].name)
        // state.categories[i].name =
        //   state.categoriesOriginal[i].name
        state.categories[i].editing = false
      }
    })
  },

  EDIT_NAME (state, payload) {
    // Object.entries(state.categories).forEach(([i, e]) => {
    //   if (e.id === payload.item.id) {
    //     state.categories[i].name = payload.value
    //   }
    // })
    const item = state.categories.find(m => m.id === payload.id)
    console.log(item)
    item.name = payload.name
  },
  UPDATE_CATEGORY (state, payload) {
    // 카테고리가 변경되었으니 찾아서 바꾸자
    Object.entries(state.categories).forEach(([i, e]) => {
      if (e.id === payload.id) {
        state.categories[i].name = payload.name
      }
    })
  },
  REMOVE_CATEGORY (state, payload) {
    // 카테고리가 변경되었으니 찾아서 바꾸자
    Object.entries(state.categories).forEach(([i, e]) => {
      if (e.id === payload.id) {
        state.categories.splice(i, 1)
      }
    })
  },
  ADD_CATEGORY (state, payload) {
    // 카테고리가 추가되었으니 넣자
    state.categories.push(payload)
  }
}

export const actions = {
  setCategories ({ commit }, payload) {
    Object.entries(payload).forEach(([i, e]) => {
      payload[i].editing = false
    })
    commit('SET_CATEGORIES', payload)
  },

  setEditable ({ commit }, payload) {
    commit('SET_EDITABLE', payload)
  },

  pushCategory ({ commit }, payload) {
    payload.editing = false
    commit('PUSH_CATEGORY', payload)
  },

  cancelEditable ({ commit }, payload) {
    commit('CANCEL_EDITABLE', payload)
  },

  editName ({ commit }, payload) {
    commit('EDIT_NAME', payload)
  },

  updateCategory ({ commit }, payload) {
    commit('UPDATE_CATEGORY', payload)
    commit('CANCEL_EDITABLE', payload)
  },

  removeCategory ({ commit }, payload) {
    commit('REMOVE_CATEGORY', payload)
  },

  addCategory ({ commit }, payload) {
    commit('ADD_CATEGORY', payload)
  }
}

export const getters = {}
