<template>
  <v-container class="minimal-header-wrap">
    <div>
      <NuxtLink to="/">
        <v-img id="header-logo" max-width="193" src="/images/header-logo@2x.png" alt="재료공장 로고" transition="none"></v-img>
      </NuxtLink>
    </div>
    <div class="d-flex align-center">
      <button v-if="isMobile" class="hamburger" @click="toggleMenu">
        <v-icon>mdi-menu</v-icon>
      </button>
      <collapse-transition>
        <ul v-show="showMenu" id="gnb">
          <li>
            <NuxtLink exact-active-class="on" to="/map"> 지도에서 재료찾기 </NuxtLink>
          </li>
          <li>
            <NuxtLink
              exact-active-class="on"
              :to="{
                path: '/material',
                query: {},
              }"
            >
              재료 목록
            </NuxtLink>
          </li>
          <li>
            <NuxtLink exact-active-class="on" to="/upcycleGallery"> 업사이클 갤러리 </NuxtLink>
          </li>
          <!-- <li>
            <NuxtLink exact-active-class="on" to="notice">공지사항</NuxtLink>
          </li> -->
          <!-- <li>
            <NuxtLink exact-active-class="on" to="community">커뮤니티</NuxtLink>
          </li> -->
          <li v-if="!$auth.loggedIn">
            <NuxtLink exact-active-class="on" to="/login" class="login"> 로그인 </NuxtLink>
          </li>
          <li v-if="!$auth.loggedIn">
            <NuxtLink exact-active-class="on" to="/register" class="register">회원가입</NuxtLink>
          </li>
        </ul>
      </collapse-transition>
      <div v-if="$auth.loggedIn">
        <v-menu bottom min-width="200px" rounded offset-y>
          <template #activator="{ on }">
            <v-btn icon small v-on="on">
              <v-avatar color="primary" :size="isMobile ? 24 : 36">
                <img v-if="$auth.user.avatar !== '' && $auth.user.avatar" alt="Avatar" :src="$auth.user.avatar" />
                <span v-else>
                  <v-icon class="white--text text-h6">mdi-account</v-icon>
                </span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card elevation="0">
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <div class="mx-auto text-center">
                    <v-avatar color="primary" size="48">
                      <img v-if="$auth.user.avatar !== '' && $auth.user.avatar" alt="Avatar" :src="$auth.user.avatar" />
                      <span v-else>
                        <v-icon class="white--text text-h3">mdi-account</v-icon>
                      </span>
                    </v-avatar>
                    <h3 class="mt-2">{{ $auth.user.nickname }}</h3>
                    <p class="text-caption mt-1">
                      {{ $auth.user.email }}
                    </p>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item link to="/my/profile">
                <v-list-item-content class="text-center">
                  <v-list-item-title>프로필</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item link to="/my/materials">
                <v-list-item-content class="text-center">
                  <v-list-item-title>나의 재료</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item link to="/my/galleries">
                <v-list-item-content class="text-center">
                  <v-list-item-title>나의 갤러리</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/my/communities">
                <v-list-item-content class="text-center">
                  <v-list-item-title>나의 커뮤니티</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item link to="/my/comments">
                <v-list-item-content class="text-center">
                  <v-list-item-title>나의 댓글</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item link to="/my/changePassword">
                <v-list-item-content class="text-center">
                  <v-list-item-title>비밀번호 변경</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item link @click.prevent="logout">
                <v-list-item-content class="text-center">
                  <v-list-item-title>로그아웃</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'MinimalGnbComponent',
  components: {},
  data() {
    return {
      isMobile: false,
      showMenu: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkViewport)
    this.checkViewport()
  },
  destroyed() {
    window.removeEventListener('resize', this.checkViewport)
  },
  methods: {
    checkViewport() {
      this.isMobile = window.innerWidth < 768
      if (!this.isMobile) this.showMenu = true
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
  },
}
</script>
<style lang="scss" scoped>
.minimal-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  top: 20px;
  left: 20px;
  width: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .hamburger {
    display: none;
  }

  #header-logo {
    width: 120px;
  }

  #gnb {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      display: inline-block;
      margin: 0 10px;
      a {
        color: #000;
        font-size: 0.825rem;
        font-weight: 500;
        &.on {
          color: #000;
          font-weight: 700;
        }
      }
    }

    .login {
      color: $color__sig;
    }

    .register {
      font-size: 0.825rem;
      padding: 7px 15px;
      background: $color__sig;
      border-radius: 4px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 767px) {
  .minimal-header-wrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    width: 90%;

    .hamburger {
      display: block;
    }

    #header-logo {
      width: 80px;
    }

    #gnb {
      // display: none;
      // border-top: 1px solid $color__border-default;
      border-radius: 10px;
      border-bottom: 1px solid $color__border-default;
      flex-direction: column;
      gap: 0;
      left: 0;
      top: 55px;
      position: absolute;
      width: 100%;

      background-color: #fff;
      li {
        width: 100%;
        text-align: center;
        a {
          display: block;
          padding: 15px 0;
          text-align: center;

          &:not(.register) {
            width: 100%;
          }

          &.register {
            display: inline-block;
            font-size: 1rem;
            margin: 10px auto;
            border-radius: 4px;
          }
        }
      }
      li + li {
        border-top: 1px solid $color__border-default;
      }
    }
  }
}
</style>
